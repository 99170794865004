import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { AppComponent } from '../app.component';
import { StreamerService } from '../streamer.service';

@Component({
  selector: 'app-mainpage',
  templateUrl: './mainpage.component.html',
  styleUrls: ['./mainpage.component.css']
})
export class MainpageComponent implements OnInit {

  //pink #e088e1
  //green #8cd38c
  urlSafe: SafeResourceUrl;

  constructor(private streamerService: StreamerService) { 
  }

  ngOnInit() {
    this.streamerService.stream1.embed = new Twitch.Embed('twitch-stream1', {
      channel: this.streamerService.stream1.channel,
      layout: 'video',
      theme: 'dark',
      width: "100%",
      height: "100%",
      muted: false,
      parent: [
        'confidence.alwaysdox.de'
      ]
    });

    this.streamerService.stream2.embed = new Twitch.Embed('twitch-stream2', {
      channel: this.streamerService.stream2.channel,
      layout: 'video',
      theme: 'dark',
      width: "100%",
      height: "100%",
      muted: true,
      parent: [
        'confidence.alwaysdox.de'
      ]
    });

    this.streamerService.stream3.embed = new Twitch.Embed('twitch-stream3', {
      channel: this.streamerService.stream3.channel,
      layout: 'video',
      theme: 'dark',
      width: "100%",
      height: "100%",
      muted: true,
      parent: [
        'confidence.alwaysdox.de'
      ]
    });

  }

  toggleChat(event) {
    const chatbox = document.getElementById(event.srcElement.id + '-chatbox');
    const videobox = document.getElementById(`twitch-${event.srcElement.id}-video`);

    console.log(chatbox.style.height);
    if(chatbox.style.height == '0px' || chatbox.style.height == ''){
      chatbox.style.height = '300px'; 
      videobox.style.height = '627px';
    } else { 
      chatbox.style.height = '0px';
      videobox.style.height = '340px';
    }

  }

}
