import { Component, HostListener } from '@angular/core';
import { StreamerService } from './streamer.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  constructor(public streamerService: StreamerService){}

  title = 'confidence-webpage';

  public static currentWidth = 580;
  public static currentHeight = 330;

  @HostListener('window:resize', ['$event'])
    onResize(event) {
    }
}
