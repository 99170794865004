import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class StreamerService {
  public stream1: any;
  public stream2: any;
  public stream3: any;

  constructor(public sanitizer: DomSanitizer) {
    this.stream1 = {
      channel: 'alwaysdox',
      chat: this.sanitizer.bypassSecurityTrustResourceUrl('https://www.twitch.tv/embed/alwaysdox/chat?darkpopout&parent=confidence.alwaysdox.de'),
      url: 'https://twitch.tv/alwaysdox',
      embed: null
    };
    this.stream2 = {
      channel: 'bulli182',
      chat:  this.sanitizer.bypassSecurityTrustResourceUrl('https://www.twitch.tv/embed/bulli182/chat?darkpopout&parent=confidence.alwaysdox.de'),      
      url: 'https://twitch.tv/bulli182',
      embed: null
    };
    this.stream3 = {
      channel: 'iamswain7',
      chat:  this.sanitizer.bypassSecurityTrustResourceUrl('https://www.twitch.tv/embed/iamswain7/chat?darkpopout&parent=confidence.alwaysdox.de'),      
      url: 'https://twitch.tv/iamswain7',
      embed: null
    };
   }
}